import logo from "./logo.svg";
import "./App.css";
import HomePage from "./views/homepage";
import { DetailsPage } from "./views/detailspage";
import ContactPage from "./views/contact";
import { BrowserRouter, Routes, Route, Outlet, Link } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

function Layout() {
  return (
    <div className="layout">
      <header className="header">
        <img className="icon" src="website-icon.png" />
        <h1>Aslan SOFTWARE STUDIO</h1>
        <div
          style={{ width: "70%", justifyContent: "end", alignItems: "center" }}
        >
          <nav className="nav">
            <Link className="nav-link" to="/">
              Ana Sayfa
            </Link>
            <Link className="nav-link" to="/details">
              Detaylar
            </Link>
            <Link className="nav-link" to="/contact">
              İletişim
            </Link>
          </nav>
        </div>
      </header>
      <main>
        <Outlet />
      </main>
    </div>
  );
}

function App() {
  return (
    <AnimatePresence>
      <BrowserRouter>
        <Routes>
          <Route element={<Layout />} path="/">
            <Route index element={<HomePage />} />
            <Route element={<DetailsPage />} path="/details" />
            <Route element={<ContactPage />} path="/contact" />
          </Route>
        </Routes>
      </BrowserRouter>
    </AnimatePresence>
  );
}

export default App;
