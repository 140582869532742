import React from "react";
import "../App.css";
import { motion } from "framer-motion";
function ContactPage() {
  return (
    <motion.div initial={{ y: 100 }} animate={{ y: 0 }} exit={{ y: -100 }}>
      <>
        <div className="contactContainer">
          <div className="contactInfo">
            <div className="contactYapi">
              <img className="contact-icon" src="website-icon.png" />
              <h2 className="contact-title">Aslan SOFTWARE STUDIO</h2>
            </div>
            <div className="contactContext">
              <h3>
                Instagram:{" "}
                <a
                  href="https://www.instagram.com/aslansoftwarestudio/"
                  className="contact-insta"
                >
                  @aslansoftwarestudio
                </a>
              </h3>

              <h3 className="mobil-baslik">
                Google Play Store:
                <a
                  href="https://play.google.com/store/apps/dev?id=7211423329256330757&gl=TR"
                  className="contact-insta"
                >
                  @aslansoftwarestudio
                </a>
              </h3>

              <h3>E-Posta:mkysln4@gmail.com</h3>
            </div>
          </div>
        </div>

        <footer className="footer">
          <p>© 2024 Aslan Software Studio</p>
        </footer>
      </>
    </motion.div>
  );
}

export default ContactPage;
