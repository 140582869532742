import React from "react";
import { motion } from "framer-motion";

export function DetailOne() {
  return (
    <div className="container">
      <div className="detailContainer">
        <h2 className="detailH2">
          Aslan SOFTWARE STUDIO <br /> Olarak Sizlere Teknolojiyi Daha Yakın,
          Ulaşılabilir Ve Kolay Kılmayı Amaçlıyoruz.
        </h2>
        <p className="detailDescription">
          Dijital dünyada en yenilikçi çözümleri sunarak, işletmenizin dijital
          dönüşümünü hızlandırıyoruz. Mobil ve web uygulama geliştirme, yazılım
          çözümleri ve çok daha fazlasıyla işinizi modern dünyanın ihtiyaçlarına
          uygun hale getiriyoruz. Deneyimli ekibimizle teknolojiyi her adımda
          daha erişilebilir kılmak için buradayız!
        </p>
      </div>
    </div>
  );
}

export function DetailTwo() {
  return (
    <div className="detailContainer">
      <div className="detailRow">
        <div>
          <h2 className="detailDescTitle">Web Application</h2>
          <p className="detailDownDesc">
            Sizler için en güncel teknolojileri kullanarak harika web site ve
            uygulamaları geliştirmeyi amaçlıyoruz.
          </p>
        </div>
        <img
          style={{ borderRadius: "85px" }}
          src="https://static-00.iconduck.com/assets.00/react-icon-256x256-sv8qujwm.png"
          alt="resim"
          className="detailPict"
        />
      </div>
    </div>
  );
}

export function DetailThree() {
  return (
    <div className="detailContainer">
      <div className="detailRow">
        <img
          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTkD0hl4RMkT1HCsSWKPEG-UqrOfJa8l7wx5w&s"
          alt="resim"
          className="detailPict"
        />
        <div>
          <h2 className="detailDescTitle">Mobile Application</h2>
          <p className="detailDownDesc">
            Kullanıcı deneyimini ön planda tutarak, mobil uygulamalar
            geliştiriyoruz.
          </p>
        </div>
      </div>
    </div>
  );
}

export function DetailFour() {
  return (
    <div className="detailContainer">
      <div className="detailRow">
        <div>
          <h2 className="detailDescTitle">Desktop Application</h2>
          <p className="detailDownDesc">
            Sizler için en güncel teknolojileri kullanarak harika Masaüstü
            uygulamaları ve hayatınızı kolaylaştıran programlar geliştirmeyi
            amaçlıyoruz.
          </p>
        </div>
        <img
          style={{ borderRadius: "85px" }}
          src="https://avatars.githubusercontent.com/u/157004365?s=200&v=4"
          alt="resim"
          className="detailPict"
        />
      </div>
    </div>
  );
}

export function DetailFive() {
  return (
    <div className="detailContainer">
      <h2 className="detailDescTitle">Yazılım Çözümleri</h2>
      <p className="detailDownDesc">
        İşletmenizin ihtiyaçlarına uygun yazılım çözümleri sunuyoruz.
      </p>
    </div>
  );
}

export function DetailFooter() {
  return (
    <div>
      <footer className="footer">
        <p>© 2024 Aslan Software Studio</p>
      </footer>
    </div>
  );
}

function DetailsPage() {
  return (
    <motion.div initial={{ y: 100 }} animate={{ y: 0 }} exit={{ y: -100 }}>
      <div>
        <DetailOne />
        <hr />
        <DetailTwo />
        <hr />
        <DetailThree />
        <hr />
        <DetailFour />
        <hr />
        <DetailFive />
        <hr />
        <DetailFooter />
      </div>
    </motion.div>
  );
}

export { DetailsPage };
