import React from "react";
import "../App.css";
import homepage from "../img/homepage.jpg";
import { useNavigate } from "react-router-dom";
import DetailsPage from "./detailspage";
import { motion } from "framer-motion";

function Homepage() {
  const navigate = useNavigate();
  function SeeDetail() {
    return navigate("/details");
  }
  return (
    <motion.div initial={{ y: 100 }} animate={{ y: 0 }} exit={{ y: -100 }}>
      <>
        <div className="container">
          <div className="row">
            <div>
              <h1 className="yazi">Teknolojiye, Bir</h1>
              <h1 className="yazi">Adım Daha Yakın</h1>
            </div>

            <img className="homepict" src={homepage} alt="resim" />
          </div>
          <div>
            {/*

            
            <button
              className="button"
              onClick={SeeDetail}
              style={{ cursor: "pointer" }}
            >
              Ayrıntılı Bilgi
            </button> */}
          </div>
        </div>
      </>
    </motion.div>
  );
}

export default Homepage;
